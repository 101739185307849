import { setSelectedTag } from '../../../../redux/marks/currentMarkingSlice';

const RECT = 'rect';
const BORDER = 'border';
const SVG = 'svg';
const OBJECT = 'object';
const OPEN_SELECT = 'openselect';
const MARKER = 'mark_marker';
const CANVAS = 'CANVAS';
const RECTANGLE = 'rectangle';
const TSPAN = 'tspan';
const LABEL = 'label';

export const calculateMarkCoordinatesOnTouch = (
  touch,
  rect,
  imageRotation,
  imageSize,
  imageScalingData
) => {
  const { rotateX, rotateY, rotateZ } = imageRotation;
  const { calculatedImageWidth, calculatedImageHeight } = imageSize;
  const { clientX, clientY } = touch;
  const { rotationScaleDownCompensation, imageScale } = imageScalingData;

  let x = clientX - rect.left;
  let y = clientY - rect.top;

  // marking position calculation
  if (rotateZ === -90 || rotateZ === 270) {
    y = (clientX - rect.left) * rotationScaleDownCompensation;
    x =
      calculatedImageWidth -
      (clientY - rect.top) * rotationScaleDownCompensation;
  }

  if (rotateZ === 90 || rotateZ === -270) {
    y =
      calculatedImageHeight -
      (clientX - rect.left) * rotationScaleDownCompensation;
    x =
      calculatedImageWidth -
      (calculatedImageWidth -
        (clientY - rect.top) * rotationScaleDownCompensation);
  }

  if (rotateZ === 180 || rotateZ === -180) {
    y = calculatedImageHeight - y;
    x = calculatedImageWidth - x;
  }

  if (rotateY === -180) {
    if (
      rotateZ === -90 ||
      rotateZ === 270 ||
      rotateZ === 90 ||
      rotateZ === -270
    ) {
      y = calculatedImageHeight - y;
    } else {
      x = calculatedImageWidth - x;
    }
  }

  if (rotateX === 180) {
    if (
      rotateZ === -90 ||
      rotateZ === 270 ||
      rotateZ === 90 ||
      rotateZ === -270
    ) {
      x = calculatedImageWidth - x;
    } else {
      y = calculatedImageHeight - y;
    }
  }

  // fit position calculation for current image scale
  x = x / imageScale;
  y = y / imageScale;

  return { x, y };
};

const isClassName = (className, stringClassName) =>
  className?.toLowerCase().includes(stringClassName);
const isNodeName = (event, nodeName) => event.target?.nodeName === nodeName;

export const isLabel = (event) => {
  return (
    event.target &&
    (isNodeName(event, TSPAN) || isNodeName(event, RECT)) &&
    event.target.__data__ &&
    event.target.__data__.group === LABEL
  );
};

export const addCenterPoint = (
  event,
  disableMarking,
  bordersAndTags,
  setStateCb,
  dispatch
) => {
  const { cluster, text } = event.target.__data__;
  if (disableMarking) return;
  const currentBorder = bordersAndTags.nodes.find(
    (n) => n.group === BORDER && n.cluster === cluster
  );

  const filteredDetails = [];
  const relevantIndications = text.split('\n');
  relevantIndications.forEach((i) => {
    const details = i.split(':')[0];
    details === 'Surface Caries'
      ? filteredDetails.push('Surface Caries: Regular')
      : filteredDetails.push(details);
  }); // TODO Check if can use getIndicateId
  dispatch(setSelectedTag(filteredDetails));

  setStateCb(
    {
      x: currentBorder.positionX + currentBorder.width / 2,
      y: currentBorder.positionY + currentBorder.height / 2,
      copy: false,
    },
    filteredDetails
  );
};

// this function prevents marking when dragging
export const mouseUpHandler = (
  event,
  mouseDownPosition,
  disableMarking,
  setStateCb,
  bordersAndTags,
  dispatch
) => {
  const className = event.target.className;
  const classIsNotObject = typeof className !== OBJECT;
  if (
    (isNodeName(event, RECT) && event.target.__data__.group === BORDER) ||
    isNodeName(event, SVG) ||
    (className &&
      classIsNotObject &&
      !isClassName(className, OPEN_SELECT) &&
      !isClassName(className, MARKER)) ||
    isNodeName(event, CANVAS)
  ) {
    const { mouseDownX, mouseDownY } = mouseDownPosition;
    const { clientX, clientY } = event;
    if (mouseDownX === clientX && mouseDownY === clientY) {
      if (disableMarking) return;
      let x, y;
      if (classIsNotObject && isClassName(className, RECTANGLE)) {
        x = event.target.offsetLeft + event.nativeEvent.offsetX;
        y = event.target.offsetTop + event.nativeEvent.offsetY;
      } else {
        //the offset of an event object exists directly inside it in bounding box mode
        x = event.nativeEvent ? event.nativeEvent.offsetX : event.offsetX;
        y = event.nativeEvent ? event.nativeEvent.offsetY : event.offsetY;
      }
      setStateCb({
        x,
        y,
      });
    }
  } else if (isLabel(event)) {
    addCenterPoint(event, disableMarking, bordersAndTags, setStateCb, dispatch);
  }
};

export const calcTouchCoordinates = (
  event,
  imageScale,
  internalImageScale,
  imageRotation
) => {
  const originalImageWidth = event.target.width;
  const originalImageHeight = event.target.height;
  const imageSize = {
    calculatedImageWidth: originalImageWidth * imageScale,
    calculatedImageHeight: originalImageHeight * imageScale,
  };

  const imageScalingData = {
    rotationScaleDownCompensation: 1 / internalImageScale,
    imageScale,
  };
  const touch =
    event.nativeEvent.touches[0] || event.nativeEvent.changedTouches[0];

  const rect = event.target.getBoundingClientRect();

  const coordinates = calculateMarkCoordinatesOnTouch(
    touch,
    rect,
    imageRotation,
    imageSize,
    imageScalingData
  );
  return coordinates;
};

export const mapDetailsToLabelers = (cluster, tier, detailsMap) => {
  const detailsToLabelers = new Map();
  cluster.forEach((m) => {
    const labeler = tier === 2 ? m.labeler.substring(0, 2) : 'tier' + m.tier;
    if (detailsToLabelers.has(detailsMap[m.detailsId])) {
      detailsToLabelers.set(
        detailsMap[m.detailsId],
        detailsToLabelers.get(detailsMap[m.detailsId]) + ', ' + labeler
      );
    } else {
      detailsToLabelers.set(detailsMap[m.detailsId], labeler);
    }
  });
  return detailsToLabelers;
};

export const getClusterText = (cluster, tier, detailsMap) => {
  const detailsToLabelers = mapDetailsToLabelers(cluster, tier, detailsMap);
  let labelText = '';
  detailsToLabelers.forEach(
    (value, key) => (labelText += `${key}: ${value}\n`)
  );
  return labelText.slice(0, -1);
};

export const getMinMaxXY = (clusteredMarks, key) => {
  const xValues = clusteredMarks[key].map((m) => m.x);
  const yValues = clusteredMarks[key].map((m) => m.y);
  return {
    minX: Math.min(...xValues),
    maxX: Math.max(...xValues),
    minY: Math.min(...yValues),
    maxY: Math.max(...yValues),
  };
};

/**
 * This function groups the marks objects array into clusters,
 * for example, [{cluster: 0, ...},{cluster: 0, ...}] => {0: [{cluster: 0, ...},{cluster: 0, ...}]}
 * @param {marks} marks
 */
export const groupByCluster = (marks) => {
  return marks.reduce(
    (result, mark) => ({
      ...result,
      [mark['cluster']]: [...(result[mark['cluster']] || []), mark],
    }),
    {}
  );
};

import { Box, TextField } from '@mui/material';
import React from 'react';
import styles from './FindingNameInput.module.css';

const FindingNameInput = (props) => {
  const { label, type, setType } = props;
  const handleChange = (e) => {
    const updatedType = {
      TypeId: type?.TypeId || null,
      Type: e.target.value,
    };
    setType(updatedType);
  };

  return (
    <Box className={styles.inputContainer}>
      <TextField
        required
        label={label}
        value={type?.Type || ''}
        onChange={handleChange}
        fullWidth
      />
    </Box>
  );
};

export default FindingNameInput;

import store from '../../../../redux/store';

export const SubTypes = () => {
  const toothLevelFindings = store.getState().tasks.toothLevelFindings;

  return toothLevelFindings
    ? toothLevelFindings?.subTypes?.reduce((acc, type) => {
        acc[type.SubType] = type.SubType;
        return acc;
      }, {})
    : {};
};

import { useState, useRef } from 'react';
import store from '../../../redux/store';

const AddOrEditToothLevelFindingStates = ({ tasksListLogic }) => {
  const toothLevelFindings = store.getState().tasks.toothLevelFindings;
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState(null);
  const [subtypes, setSubtypes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [findings, setFindings] = useState(toothLevelFindings);
  const submitRef = useRef();

  const addOrEditToothLevelFindingProps = {
    tasksListLogic,
    isEdit,
    setIsEdit,
    type,
    setType,
    subtypes,
    setSubtypes,
    materials,
    setMaterials,
    findings,
    setFindings,
    submitRef,
  };

  const addOrEditToothLevelFindingButtons = [];

  return { addOrEditToothLevelFindingProps, addOrEditToothLevelFindingButtons };
};

export default AddOrEditToothLevelFindingStates;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Stack,
  Tabs,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import Header from '../TasksList/Header';
import { getUserName } from '../TasksList/TasksList.logic';
import styles from './ManagementPage.module.css';
import {
  AddUser,
  EditUser,
  AddImageLevelFinding,
  EditImageLevelFinding,
  AddCampaign,
  AddOrEditToothLevelFinding,
} from './Tabs';
import { useGetTaskListLogic } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfig,
  setUsersList,
  selectUsersList,
} from '../../redux/tasks/tasksSlice';
import { getCampaignName, getCampaignGroup } from '../../config/configUtil';
import {
  AddUserStates,
  EditUserStates,
  AddImageLevelFindingStates,
  EditImageLevelFindingStates,
  AddCampaignStates,
  EditCampaignStates,
  AddOrEditToothLevelFindingStates,
} from './Hooks';
import { CustomTab } from './Components';
import { EditCampaign } from './Tabs/AddOrEditCampaign';
import store from '../../redux/store';
import { USER_ROLES } from './Tabs/SharedLogic';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={styles.tabPanelBox}>
          {/*TODO: Fix the Typography component warning*/}
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const ManagementPage = () => {
  const dispatch = useDispatch();
  const userName = getUserName();
  const [value, setValue] = useState(0);
  const tasksListLogic = useGetTaskListLogic();
  const config = useSelector(selectConfig);
  // Shared Data
  const [findingsList, setFindingsList] = useState([]);
  const [batchesList, setBatchesList] = useState([]);
  const [editBatchesList, setEditBatchesList] = useState([]);

  const [campaignsList, setCampaignsList] = useState([]);
  const [tabSwitch, setTabSwitch] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [isCopyCampaign, setIsCopyCampaign] = useState(false);
  const fetchData = useCallback(
    async (isEdit = false, batches = null) => {
      if (isEdit) {
        const listOfBatches = await tasksListLogic.getListOfBatches(
          isEdit,
          batches
        );
        setEditBatchesList(listOfBatches);
        return;
      }
      const listOfUsers = await tasksListLogic.getListOfUsers();
      const listOfFindings = await tasksListLogic.getListOfFindings();
      const listOfBatches = await tasksListLogic.getListOfBatches();
      const listOfCampaigns = await tasksListLogic.getUserCampaignsAndRoles(
        config,
        userName,
        true
      );
      const listOfMappingCampaigns = [...listOfCampaigns.keys()].map((x) => ({
        id: x,
        name: getCampaignName(config, x),
        group: getCampaignGroup(config, x),
      }));
      dispatch(setUsersList(listOfUsers));
      setFindingsList(listOfFindings);
      setBatchesList(listOfBatches);
      setCampaignsList(listOfMappingCampaigns);
    },
    [config, dispatch, tasksListLogic, userName]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onButtonClicked = async (b) => {
    await b.onClick();
  };
  const usersList = useSelector(selectUsersList);

  // Add User
  const { addUserProps, addUserButtons } = AddUserStates({ tasksListLogic });

  // Edit User
  const { editUserProps, editUserButtons } = EditUserStates({ tasksListLogic });

  // Add Image Level Findings
  const { addImageLevelFindingProps, addImageLevelFindingButtons } =
    AddImageLevelFindingStates({ tasksListLogic, findingsList });

  //  Edit Image Level Findings
  const { editImageLevelFindingProps, editImageLevelFindingButtons } =
    EditImageLevelFindingStates({ tasksListLogic, findingsList });

  const toothLevelFindings = store.getState().tasks.toothLevelFindings;
  const { addOrEditToothLevelFindingProps, addOrEditToothLevelFindingButtons } =
    AddOrEditToothLevelFindingStates({
      tasksListLogic,
      toothLevelFindings,
    });

  //  Add Batch
  // const { addBatchButtons } = AddBatchStates({
  //   tasksListLogic,
  //   campaignsList,
  // });

  //  Add Campaign
  const { addCampaignProps, addCampaignButtons } = AddCampaignStates({
    tasksListLogic,
    campaignsList,
    findingsList,
    batchesList,
  });

  // Edit Campaign
  const { editCampaignProps, editCampaignButtons } = EditCampaignStates({
    tasksListLogic,
    campaignsList,
    findingsList,
    editBatchesList,
  });

  const tabButtons = [
    addUserButtons,
    editUserButtons,
    addImageLevelFindingButtons,
    editImageLevelFindingButtons,
    addOrEditToothLevelFindingButtons,
    addCampaignButtons,
    editCampaignButtons,
  ];
  const extraCampaignProps = {
    fetchData,
    tabSwitch,
    setTabSwitch,
    isCopyCampaign,
    setIsCopyCampaign,
  };
  const [currentTabButtons, setCurrentTabButtons] = useState(tabButtons[0]);
  const [showAddCampaignForm, setShowAddCampaignForm] = useState(false);

  const [selectedCampaignToCopy, setSelectedCampaignToCopy] = useState(null);

  const resetToAddCampaignMainMenu = () => {
    setShowAddCampaignForm(false);
    setShowAutocomplete(false);
    setIsCopyCampaign(false);
    setSelectedCampaignToCopy(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTabButtons(tabButtons[newValue] ?? []);
    setTabSwitch(true);
    setShowAddCampaignForm(true);
    setSelectedCampaignToCopy(null);
    setIsCopyCampaign(false);
    if (newValue === 5) {
      setShowAddCampaignForm(false);
      setShowAutocomplete(false);
    }
  };
  const getRolesArray = () => {
    return USER_ROLES.map((role) => ({
      label: role,
      checked: false,
    }));
  };
  const handleCopyCampaign = async () => {
    if (selectedCampaignToCopy) {
      setShowAddCampaignForm(true);
      setShowAutocomplete(false);
      setTabSwitch(false);
      const selectedCampaignConfig = config?.campaigns.find(
        (c) => c.campaign_id === selectedCampaignToCopy.id
      );
      if (selectedCampaignConfig) {
        addCampaignProps.setCampaignName(
          `copy of ${selectedCampaignToCopy.name}`
        );
        setIsCopyCampaign(true);

        addCampaignProps.setSelectedUsersAndRoles((prev) =>
          usersList.map((user) => {
            return {
              ...user,
              roles:
                prev.find((u) => u.UserId === user.UserId)?.roles ??
                getRolesArray(),
            };
          })
        );
      }
    }
  };
  const FooterButtons = () => {
    if (!showAddCampaignForm) {
      if (showAutocomplete) {
        return (
          <div className={styles.buttonsContainer}>
            <Button variant="outlined" onClick={handleCopyCampaign}>
              Copy
            </Button>{' '}
          </div>
        );
      }
      return null;
    }
    return (
      <Stack className={styles.buttonsContainer} direction="row">
        {currentTabButtons
          ? currentTabButtons.map((b) => (
              <Button
                key={b.label}
                onClick={() => onButtonClicked(b)}
                variant="outlined"
                disabled={b.hidden}
              >
                {b.label}
              </Button>
            ))
          : null}
      </Stack>
    );
  };

  return (
    <Box className={styles.page}>
      <Header name={userName} isAdminPage={true} role="tier1" />
      <Box className={styles.body}>
        <Box className={styles.tabsContainer}>
          <Tabs orientation="vertical" value={value} onChange={handleChange}>
            <CustomTab label="Add User" {...a11yProps(0)} />
            <CustomTab label="Edit User" {...a11yProps(1)} />
            <CustomTab label="Add Image Level Finding" {...a11yProps(2)} />
            <CustomTab label="Edit Image Level Finding" {...a11yProps(3)} />
            <CustomTab label="Tooth Level Finding" {...a11yProps(4)} />
            <CustomTab label="Add Campaign" {...a11yProps(5)} />
            <CustomTab label="Edit Campaign" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <Box className={styles.frameContainer}>
          <FormControl>
            <TabPanel value={value} index={0}>
              <AddUser {...addUserProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EditUser {...editUserProps} fetchData={fetchData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AddImageLevelFinding
                {...addImageLevelFindingProps}
                fetchData={fetchData}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EditImageLevelFinding
                {...editImageLevelFindingProps}
                fetchData={fetchData}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AddOrEditToothLevelFinding
                {...addOrEditToothLevelFindingProps}
                toothLevelFindings={toothLevelFindings}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              {!showAddCampaignForm ? (
                <Stack spacing={2} className={styles.addCampaignContainer}>
                  <Stack
                    direction="row"
                    spacing={2}
                    className={styles.stepperButtons}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowAddCampaignForm(true);
                        setShowAutocomplete(false);
                      }}
                    >
                      Add Campaign
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowAutocomplete(true);
                        setShowAddCampaignForm(false);
                        setTabSwitch(true);
                      }}
                    >
                      Copy from existing campaign
                    </Button>
                  </Stack>
                  {showAutocomplete && (
                    <>
                      <Autocomplete
                        options={campaignsList}
                        getOptionLabel={(option) => option.name || ''}
                        value={selectedCampaignToCopy || ''}
                        onChange={(event, newValue) =>
                          setSelectedCampaignToCopy(newValue)
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Copy existing campaign"
                            required
                            variant="outlined"
                          />
                        )}
                        selectOnFocus
                        handleHomeEndKeys
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <AddCampaign
                  {...addCampaignProps}
                  {...extraCampaignProps}
                  resetToAddCampaignMainMenu={resetToAddCampaignMainMenu}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={6}>
              <EditCampaign {...editCampaignProps} {...extraCampaignProps} />
            </TabPanel>
          </FormControl>

          <FooterButtons />
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementPage;

import {
  IconButton,
  List,
  ListItem,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import styles from './SubtypeMaterialListComp.module.css';
import AddIcon from '@mui/icons-material/Add';

const SubtypeMaterialListComp = (props) => {
  const {
    type,
    subtypes,
    setSubtypes,
    materials,
    setMaterials,
    toothLevelFindings,
  } = props;
  const selectedType =
    toothLevelFindings.typesSubTypesMaterials?.find(
      (findingType) => findingType.TypeSubTypeMaterialId === type?.TypeId
    ) || null;

  const subTypeLength =
    selectedType?.['TypeSubTypeMaterial']?.[type.Type]?.['Sub Type']?.length ||
    0;
  const materialLength =
    selectedType?.['TypeSubTypeMaterial']?.[type.Type]?.['Material']?.length ||
    0;

  const allSubTypes = toothLevelFindings.subTypes;
  const allMaterials = toothLevelFindings.materials;

  const handleAddSubType = () => {
    setSubtypes([...subtypes, { SubType: '', SubTypeId: null }]);
  };

  const handleAddMaterial = () => {
    setMaterials([...materials, { Material: '', MaterialId: null }]);
  };

  const handleDeleteSubType = (index) => {
    setSubtypes(subtypes.filter((_, i) => i !== index));
  };

  const handleDeleteMaterial = (index) => {
    setMaterials(materials.filter((_, i) => i !== index));
  };

  const handleSubTypeChange = (data, index, value) => {
    const newSubTypes = [...subtypes];
    const existingSubType = allSubTypes.find(
      (subType) => subType.SubType === value
    );
    if (existingSubType) {
      newSubTypes[index] = {
        SubType: existingSubType.SubType,
        SubTypeId: existingSubType.SubTypeId,
      };
    } else {
      newSubTypes[index] = {
        SubType: value,
        SubTypeId: null,
      };
    }
    setSubtypes(newSubTypes);
  };

  const handleMaterialChange = (data, index, value) => {
    const newMaterials = [...materials];
    const existingMaterial = allMaterials.find(
      (material) => material.Material === value
    );
    if (existingMaterial) {
      newMaterials[index] = {
        Material: existingMaterial.Material,
        MaterialId: existingMaterial.MaterialId,
      };
    } else {
      newMaterials[index] = {
        Material: value,
        MaterialId: null,
      };
    }
    setMaterials(newMaterials);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ textAlign: 'center', marginTop: '0px' }}
      >
        <Grid item xs={6} style={{ borderRight: '1px solid grey' }}>
          <Button
            variant="outlined"
            disabled
            style={{ color: 'black', borderColor: 'black' }}
          >
            SUB TYPE
          </Button>
          <IconButton edge="end" aria-label="add" onClick={handleAddSubType}>
            <AddIcon />
          </IconButton>
          <List className={styles.list}>
            {subtypes.map(
              (data, index) =>
                data.SubType !== 'Not Selected' && (
                  <ListItem key={index} className={styles.listItem}>
                    <div className={styles.detailsExplanationField}>
                      <TextField
                        required
                        label="SUB TYPE"
                        value={data.SubType}
                        className={styles.textFieldName}
                        onChange={(e) =>
                          handleSubTypeChange(data, index, e.target.value)
                        }
                      />
                    </div>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteSubType(index)}
                      disabled={index < subTypeLength}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                )
            )}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled
            style={{ color: 'black', borderColor: 'black' }}
          >
            MATERIAL
          </Button>
          <IconButton edge="end" aria-label="add" onClick={handleAddMaterial}>
            <AddIcon />
          </IconButton>
          <List className={styles.list}>
            {materials.map(
              (data, index) =>
                data.Material !== 'Not Selected' && (
                  <ListItem key={index} className={styles.listItem}>
                    <div className={styles.detailsExplanationField}>
                      <TextField
                        required
                        value={data.Material}
                        label="MATERIAL"
                        className={styles.textFieldName}
                        onChange={(e) =>
                          handleMaterialChange(data, index, e.target.value)
                        }
                      />
                    </div>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteMaterial(index)}
                      disabled={index < materialLength}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                )
            )}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default SubtypeMaterialListComp;

import React from 'react';
import FindingNameInput from './FindingNameInput';
import SubtypeMaterialListComp from './SubtypeMaterialListComp';

const AddToothLevelFindingForm = (props) => {
  return (
    <>
      <FindingNameInput label="Add Type" {...props} />
      <SubtypeMaterialListComp {...props} />
    </>
  );
};

export default AddToothLevelFindingForm;

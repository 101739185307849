import store from '../../../redux/store';

export const TypesSubTypesMaterials = () => {
  const toothLevelFindings = store.getState()?.tasks?.toothLevelFindings;

  if (!toothLevelFindings) {
    return {};
  }

  const { typesSubTypesMaterials } = toothLevelFindings;

  return typesSubTypesMaterials
    ? typesSubTypesMaterials?.reduce((acc, item) => {
        const { TypeSubTypeMaterial } = item;
        if (TypeSubTypeMaterial) {
          const Type = Object.keys(TypeSubTypeMaterial)[0];
          const { 'Sub Type': SubType, Material } = TypeSubTypeMaterial[Type];
          acc[Type] = {
            'Sub Type': SubType,
            Material: Material,
          };
        }
        return acc;
      }, {})
    : {};
};

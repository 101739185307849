import React from 'react';
import AddToothLevelFindingForm from './AddToothLevelFindingForm';
import EditToothLevelFindingForm from './EditToothLevelFindingForm';

const FormContent = (props) => {
  return props.isEdit ? (
    <EditToothLevelFindingForm {...props} />
  ) : (
    <AddToothLevelFindingForm {...props} />
  );
};

export default FormContent;

import { MISSING_TOOTH, NOT_SELECTED_STR } from '../../../shared-logic/params';
import { Types } from '../ToothLabeling/Labeling/Types';
import { SubTypes } from '../ToothLabeling/Labeling/SubTypes';
import { Materials } from '../ToothLabeling/Labeling/Materials';
import { TypesSubTypesMaterials } from '../ToothLabeling/TypesSubTypesMaterials';
let types = {};
let subTypes = {};
let materials = {};
let typesSubTypesMaterials = {};
const initializeData = () => {
  types = Types();
  subTypes = SubTypes();
  materials = Materials();
  typesSubTypesMaterials = TypesSubTypesMaterials();
};
initializeData();

export const isValidToothIndication = (type, subType, material) => {
  initializeData();
  if (
    type === NOT_SELECTED_STR &&
    subType === NOT_SELECTED_STR &&
    material === NOT_SELECTED_STR
  ) {
    return false;
  }
  if (types[type]) {
    return true;
  }
  if (typesSubTypesMaterials[type]) {
    return subType !== NOT_SELECTED_STR && material !== NOT_SELECTED_STR;
  }
  if (subTypes[type]) {
    return subType !== NOT_SELECTED_STR;
  }
  if (materials[type]) {
    return material !== NOT_SELECTED_STR;
  } else {
    return false;
  }
};

export const isEmptyToothMark = (type, subType, material, comments) => {
  return (
    type === NOT_SELECTED_STR &&
    subType === NOT_SELECTED_STR &&
    material === NOT_SELECTED_STR &&
    comments.toLowerCase() === ''
  );
};

export const isMissingTooth = (tooth) => {
  return types
    ? !!tooth?.markings?.find((mark) => mark.type === types[MISSING_TOOTH])
    : false;
};

export const findCurrentToothData = (output, currentToothIndex) => {
  return output?.teeth?.find((t) => t.id === currentToothIndex);
};

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './OpenSelect.module.css';
import useFindingsDetails from '../../MarkLabeling/Labeling/FindingsDetails.js';
import KeyCodes from '../../../../shared-logic/enums/keyCodesEnum';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDetail,
  setFinding,
  recentlyUsedDetailsSelector,
  setOpenSelectHistory,
  selectedTagSelector,
  setSelectedTag,
} from '../../../../redux/marks/currentMarkingSlice';
import { labelDetailsTier2Selector } from '../../../../redux/taskState/taskDetailsSlice';
import { findingsList } from '../../../../config/configUtil';
import {
  selectCampaign,
  selectConfig,
} from '../../../../redux/tasks/tasksSlice';
import { useSetRecentlyUsedDetails } from '../../customHooks';
import {
  createFindingData,
  createDetailData,
} from '../../../../shared-logic/dataStructures';

const OpenSelect = ({ openSelectHistory, left, top }) => {
  const dispatch = useDispatch();
  const { findingsDetails } = useFindingsDetails();
  const setRecentlyUsedDetails = useSetRecentlyUsedDetails();
  const labelDetailsTier2 = useSelector(labelDetailsTier2Selector);
  const recentlyUsedDetails = useSelector(recentlyUsedDetailsSelector);
  const selectedTag = useSelector(selectedTagSelector);

  const [selected, setSelected] = useState(null);

  const [active, setActive] = useState(0);
  const [keysPressed, setKeysPressed] = useState([]);
  const ref = useRef(true);
  const campaign = useSelector(selectCampaign);
  const config = useSelector(selectConfig);

  const [allDetails, setAllDetails] = useState([]);

  useEffect(() => {
    const findingsOfCampaign = findingsList(config, campaign);
    const findings = findingsOfCampaign
      ? findingsDetails.filter((x) => findingsOfCampaign.includes(x.FindingId))
      : findingsDetails;

    const details = [];
    Object.values(findings).forEach((f) =>
      f?.Details?.slice(1).forEach((d) =>
        details.push({
          FindingId: f.FindingId,
          FindingName: f.FindingName,
          DetailId: d.id,
          DetailName: d.name,
        })
      )
    );
    setAllDetails(details);
    const filteredTagDetails = details.filter((detail) =>
      selectedTag?.includes(detail.DetailName)
    );

    if (filteredTagDetails.length > 0) {
      setFilteredItemList(filteredTagDetails);
    } else if (
      filteredTagDetails.length === 0 &&
      recentlyUsedDetails.length > 0
    ) {
      setFilteredItemList(recentlyUsedDetails);
    }
    setActive(0);
  }, [
    config,
    findingsDetails,
    campaign,
    selectedTag,
    openSelectHistory,
    recentlyUsedDetails,
  ]);

  const [filteredItemList, setFilteredItemList] = useState([]);

  useEffect(() => {
    setKeysPressed([]);
    dispatch(setOpenSelectHistory(false));
    setFilteredItemList([]);
  }, [dispatch]);

  useEffect(() => {
    // setKeysPressed([]);
    if (!openSelectHistory && keysPressed.length === 0) {
      setFilteredItemList(allDetails);
      setActive(0);
    }
  }, [allDetails, openSelectHistory, keysPressed]);

  useEffect(() => {
    if (!ref.current && selected) {
      const { FindingId, FindingName, DetailId, DetailName } = selected;
      dispatch(setFinding(createFindingData(FindingId, FindingName)));
      dispatch(setDetail(createDetailData(DetailId, DetailName)));
    } else {
      ref.current = false;
    }
  }, [selected, dispatch]);

  useEffect(() => {
    if (selected) {
      setRecentlyUsedDetails(selected);
      setSelected(null);
      dispatch(setSelectedTag(null));
      setFilteredItemList([]);
    }
  }, [selected, setRecentlyUsedDetails, dispatch]);

  const handleClick = function () {
    setSelected(filteredItemList[active]);
  };

  const isEnterAndPopupOpen = (e) => {
    e.stopPropagation();
    return (
      e.key === KeyCodes.ENTER &&
      (keysPressed.length > 0 || openSelectHistory) &&
      filteredItemList.length > 0
    );
  };

  const resetFilter = () => {
    setFilteredItemList([]);
    setKeysPressed([]);
  };

  const setDetailsAndKeys = (filteredDetails, allPressedKeys) => {
    setFilteredItemList(filteredDetails);
    setKeysPressed(allPressedKeys);
    setActive(0);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    if (e.key === KeyCodes.ARROW_DOWN && active < filteredItemList.length - 1) {
      setActive(active + 1);
    } else if (e.key === KeyCodes.ARROW_UP && active > 0) {
      setActive(active - 1);
    } else if (isEnterAndPopupOpen(e)) {
      handleClick();
      document.activeElement.blur();
    } else if (e.key === KeyCodes.ESCAPE) {
      resetFilter();
    } else if (labelDetailsTier2) {
      const filteredDetails = labelDetailsTier2.filter((i) =>
        allDetails.includes(i)
      );
      setDetailsAndKeys(filteredDetails, [e.key]);
    } else {
      let allPressedKeys = [...keysPressed, e.key];
      let filteredDetails = filterDetails(allDetails, allPressedKeys);
      if (filteredDetails.length !== 0) {
        setDetailsAndKeys(filteredDetails, allPressedKeys);
      }
    }
  };

  const filterDetails = (details, keys) =>
    details.filter((val) =>
      val.DetailName.toLowerCase().includes(keys.join(''))
    );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const background = (index) => (index === active ? '#1e90ff' : '');
  const color = (index) => (index === active ? 'white' : 'black');

  return (
    filteredItemList.length > 0 &&
    (keysPressed.length > 0 || openSelectHistory) && (
      <div className={styles.openSelect} style={{ left: left, top: top }}>
        {filteredItemList.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.listItem}
              onClick={handleClick}
              onMouseEnter={() => setActive(index)}
              style={{ background: background(index), color: color(index) }}
            >
              {item.DetailName}
            </div>
          );
        })}
      </div>
    )
  );
};

OpenSelect.propTypes = {
  /**
   * Left placement location
   */
  left: PropTypes.number,
  /**
   * Top placement location
   */
  top: PropTypes.number,
  /**
   * Function to set mark findings
   */
  setFindings: PropTypes.func,
  /**
   * Function to set mark details
   */
  setDetails: PropTypes.func,
  /**
   * boolean if right clicked
   */
  openSelectHistory: PropTypes.bool,
};

export default OpenSelect;

import React, { useState } from 'react';
import { Autocomplete, TextField, Button } from '@mui/material';
import { FormContent } from './FormContent';
import TabForm from '../TabForm';
import styles from './AddOrEditToothLevelFinding.module.css';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { useGetApiConfigs } from '../../../../hooks';
import { get } from '../../../../shared-logic/fetchApi';
import { setToothLevelFindings } from '../../../../redux/tasks/tasksSlice';
import { HourglassProgress } from '../../../../components';

const AddOrEditToothLevelFinding = (props) => {
  const {
    tasksListLogic,
    isEdit,
    toothLevelFindings,
    subtypes,
    type,
    setType,
    setSubtypes,
    materials,
    setMaterials,
    findings,
    setFindings,
    setIsEdit,
    submitRef,
  } = props;

  const [loading, setLoading] = useState(false); // Add loading state
  const options = [
    { TypeId: -1, Type: 'Add New' },
    ...toothLevelFindings.types,
  ];

  const apiConfigs = useGetApiConfigs();
  const dispatch = useDispatch();

  // Function to reset all states
  const resetAllStates = (setType, setSubtypes, setMaterials, setIsEdit) => {
    setType({ TypeId: null, Type: '' });
    setSubtypes([]);
    setMaterials([]);
    setIsEdit(false);
  };

  const filterSubtypeMaterials = (typeId, type) => {
    const selectedType = findings.typesSubTypesMaterials.find(
      (type) => type.TypeSubTypeMaterialId === typeId
    );

    if (selectedType) {
      const typeData = { TypeId: typeId, Type: type };
      const subType = selectedType['TypeSubTypeMaterial'][type]['Sub Type'];
      const material = selectedType['TypeSubTypeMaterial'][type]['Material'];
      const mappedSubType = subType.map((SubType) => {
        const found =
          findings.subTypes.find((item) => item.SubType === SubType) || {};
        return { SubType, SubTypeId: found.SubTypeId || null };
      });

      const mappedMaterial = material.map((MaterialItem) => {
        const found =
          findings.materials.find((item) => item.Material === MaterialItem) ||
          {};
        return { Material: MaterialItem, MaterialId: found.MaterialId || null };
      });
      setSubtypes(mappedSubType);
      setMaterials(mappedMaterial);
      setType(typeData);
    } else {
      setSubtypes([]);
      setMaterials([]);
      setType(null);
    }
  };

  async function getToothLevelFindingsData() {
    try {
      const toothLevelFindings = await get(
        `${apiConfigs.API_ENDPOINT}/getToothLevelFindings`
      );
      dispatch(setToothLevelFindings(toothLevelFindings));
      setFindings(toothLevelFindings);
    } catch (error) {
      console.error(`Error fetching tooth level findings:`, error);
      throw new Error(`Error fetching tooth level findings:`, error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if type.Type is "Add New"
    if (type.Type === 'Add New') {
      NotificationManager.warning('Change the type name from "Add New"');
      return;
    }

    // Check for duplicate types and subtype materials
    const subtypeNames = subtypes.map((subtype) => subtype.SubType);
    const materialNames = materials.map((material) => material.Material);
    const typeNames = toothLevelFindings.types.map((t) => t.Type);

    const hasDuplicateSubtypes =
      new Set(subtypeNames).size !== subtypeNames.length;
    const hasDuplicateMaterials =
      new Set(materialNames).size !== materialNames.length;
    const isDuplicateType = !isEdit && typeNames.includes(type.Type);

    if (hasDuplicateSubtypes || hasDuplicateMaterials || isDuplicateType) {
      NotificationManager.warning(
        isDuplicateType
          ? 'Duplicate type is not allowed'
          : 'Duplicate subtypes or materials are not allowed'
      );
      return;
    }

    const newFinding = {
      type: type.Type,
      typeId: type.TypeId,
      typeDetails: {
        subTypeData: subtypes,
        materialData: materials,
      },
      isEdit: isEdit,
    };

    setLoading(true); // Set loading to true before submission
    try {
      const tooothLevelFindingDetails =
        await tasksListLogic.postListofToothLevelFindings(newFinding);

      if (tooothLevelFindingDetails) {
        NotificationManager.success('Tooth Level Finding Added Successfully');
        getToothLevelFindingsData();

        // Reset states after success
        resetAllStates(setType, setSubtypes, setMaterials, setIsEdit);
      }
    } catch (error) {
      console.error('Error while calling API:', error);
    } finally {
      setLoading(false); // Set loading to false after submission
    }
  };
  const filteredOptions = options.filter(
    (option) => option.Type !== 'Not Selected'
  );

  return (
    <>
      {loading && (
        <div className={styles.spinnerOverlay}>
          <HourglassProgress />
        </div>
      )}
      {!loading && (
        <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
          <Autocomplete
            options={filteredOptions}
            getOptionLabel={(option) => option.Type}
            isOptionEqualToValue={(option, value) =>
              option.TypeId === value?.TypeId && option.Type === value?.Type
            }
            value={type}
            onChange={(_, value) => {
              if (!value) {
                resetAllStates(setType, setSubtypes, setMaterials, setIsEdit);
                setType({ TypeId: null, Type: '' });
              } else if (value.TypeId === -1) {
                resetAllStates(setType, setSubtypes, setMaterials, setIsEdit);
                setType({ TypeId: null, Type: 'Add New' });
              } else {
                setIsEdit(true);
                filterSubtypeMaterials(value.TypeId, value.Type);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Type" required disabled />
            )}
            selectOnFocus
            handleHomeEndKeys
          />
          <FormContent {...props} />
          <Button
            className={styles.submitButton}
            type="submit"
            disabled={loading}
            ref={submitRef}
            variant="outlined"
          >
            Submit
          </Button>
        </TabForm>
      )}
    </>
  );
};

export default AddOrEditToothLevelFinding;

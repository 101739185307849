import store from '../../../../redux/store';

export const Types = () => {
  const toothLevelFindings = store.getState().tasks.toothLevelFindings;
  return toothLevelFindings
    ? toothLevelFindings?.types?.reduce((acc, type) => {
        acc[type.Type] = type.Type;
        return acc;
      }, {})
    : {};
};

import store from '../../../../redux/store';

export const Materials = () => {
  const toothLevelFindings = store.getState().tasks.toothLevelFindings;

  return toothLevelFindings
    ? toothLevelFindings?.materials?.reduce((acc, type) => {
        acc[type.Material] = type.Material;
        return acc;
      }, {})
    : {};
};

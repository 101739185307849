import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { taskLevelSelector } from '../../../redux/taskState/taskDetailsSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import { outputSelector } from '../../../redux/taskStateImages/outputSlice';
import { currentToothIndexSelector } from '../../../redux/marks/currentMarkingSlice';
import { findCurrentToothData } from '../shared-logic';
import { Types } from '../ToothLabeling/Labeling/Types';
import { SubTypes } from '../ToothLabeling/Labeling/SubTypes';
import { Materials } from '../ToothLabeling/Labeling/Materials';
import { isEqual } from 'lodash';

const useGetToothMarkers = () => {
  const TypesData = Types();
  const SubTypesData = SubTypes();
  const MaterialsData = Materials();

  const taskLevel = useSelector(taskLevelSelector);
  const output = useSelector(outputSelector, isEqual);
  const currentToothIndex = useSelector(currentToothIndexSelector);
  const isToothLevel = isTooth(taskLevel);

  const getToothMarkers = useCallback(() => {
    if (!isToothLevel || !output) return [];
    const currentTooth = findCurrentToothData(output, currentToothIndex);
    if (currentTooth) {
      let point;
      if (currentTooth.point) {
        const { imageNumber, type } = currentTooth.point;
        point = { ...currentTooth.point?.position, imageNumber, type };
      }
      return currentTooth.markings.map((mark) => ({
        ...mark,
        type: TypesData[mark.type],
        subType: SubTypesData[mark.subType],
        material: MaterialsData[mark.material],
        point,
      }));
    }
    return [];
  }, [
    currentToothIndex,
    output,
    isToothLevel,
    TypesData,
    SubTypesData,
    MaterialsData,
  ]);

  return getToothMarkers;
};

export default useGetToothMarkers;

import React from 'react';
import FindingNameInput from './FindingNameInput';
import SubtypeMaterialListComp from './SubtypeMaterialListComp';

const EditToothLevelFindingForm = (props) => {
  return (
    <>
      <FindingNameInput label="Edit Type" {...props} />
      <SubtypeMaterialListComp {...props} />
    </>
  );
};

export default EditToothLevelFindingForm;

import * as AC from './attributeConstants';
import { getUser } from '../../config/msalConfig';
import logger from '../../logger';
import { get, post } from '../../shared-logic/fetchApi';
import Roles from './rolesEnum';
import { getBatchesList } from './apiPathNameConstants';
import { TaskLevels, TaskTypes, Tiers } from '../../shared-logic/enums';
import {
  getCampaign,
  getCampaignList,
  getTier1LabelersNumber,
  getUserCampaigns,
  getUserCampaignsIncludeHidden,
  getUserHiddenCampaigns,
} from '../../config/configUtil';
import { isTier1 } from '../../shared-logic/tiersHelpers';
import { NotificationManager } from 'react-notifications';

import {
  deleteData,
  postData,
} from '../LabelingToolPage/LabelingToolPage.logic';

const users = 'users';
const tier2 = 'tier2';
const tier1 = 'tier1';
const tier3 = 'tier3';

const params = 'params';
const tasks_source = 'tasks_source';
const hard_coded = 'hard_coded';
const batch = 'batches';
const number_of_labelers = 'number_of_labelers';

let campaignsRoles = new Map();

async function getTasksData(
  url = '',
  isSupervisorOrWatcher = false,
  isTier2Reviewer = false
) {
  const tasks = [];
  try {
    let data = await get(url);
    if (data) {
      const filteredData = filterOutPotentialDeletedTasks(data);
      const filteredCompletedTasks = isSupervisorOrWatcher
        ? filteredData
        : isTier2Reviewer
        ? filterTier2AndCompletedTasks(filteredData)
        : filterNotCompletedTasks(filteredData);
      filteredCompletedTasks.forEach((task) => {
        tasks.push(restructureTask(task));
      });
      return tasks;
    } else {
      return null;
    }
  } catch (err) {
    logger
      .error('getTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

const filterOutPotentialDeletedTasks = (tasks) => {
  return tasks.filter((task) => !task[AC.POTENTIALLY_DISCARDED]);
};

const filterNotCompletedTasks = (tasks) => {
  return tasks.filter(
    (task) =>
      !task[AC.COMPLETED] || task[AC.COMPLETED].toLowerCase() === 'false'
  );
};

const filterTier2AndCompletedTasks = (tasks) => {
  return tasks.filter(
    (task) =>
      task[AC.COMPLETED] &&
      task[AC.COMPLETED].toLowerCase() === 'true' &&
      task[AC.TIER] === 2
  );
};

const restructureTask = (task) => {
  let percentCompleted = 0;
  if (task[AC.COMMITTED_IMAGES]) {
    percentCompleted = calculatePercentCompleted(task);
  }
  const tier = task[AC.TIER] ? parseInt(task[AC.TIER]) : Tiers.TIER_1;
  return {
    TaskId: task[AC.TASK_SHORT_NICKNAME],
    PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
    PercentCompleted: percentCompleted,
    Assignee: task[AC.ASSIGNEE],
    Tier: tier,
    Type: task[AC.TYPE],
    Level: task[AC.LEVEL],
    Batch: task[AC.BATCH],
    Completed: task[AC.COMPLETED],
    InTier1: task[AC.IN_TIER1],
    ShowMissPointsInProgress: task[AC.SHOW_MISSPOINTS_INPROGRESS],
    InactivatedTask: task[AC.INACTIVATED_TASK] || false,
  };
};

async function getDeletedTasksData(url = '') {
  const tasks = [];
  try {
    const data = await get(url);
    data.forEach((task) => {
      const obj = {
        TaskId: task[AC.TASK_SHORT_NICKNAME],
        Type: task[AC.TYPE],
        Reason: task[AC.REASON],
        Level: task[AC.LEVEL],
        Assignee: task[AC.ASSIGNEE],
      };
      tasks.push(obj);
    });

    return tasks;
  } catch (err) {
    logger
      .error('getDeletedTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

async function getPotentiallyDeletedTasksData(url = '') {
  const tasks = [];
  try {
    const data = await get(url);
    data.forEach((task) => {
      const obj = {
        TaskId: task[AC.TASK_SHORT_NICKNAME],
        Type: task[AC.TYPE],
        Reason: task[AC.REASON],
        Level: task[AC.LEVEL],
        PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
        Assignee: task[AC.ASSIGNEE],
        Tier: task[AC.TIER],
        Batch: task[AC.BATCH],
        Completed: task[AC.COMPLETED],
        InTier1: task[AC.IN_TIER1],
        PotentiallyDiscarded: task[AC.POTENTIALLY_DISCARDED],
      };
      tasks.push(obj);
    });

    return tasks;
  } catch (err) {
    logger
      .error('getPotentiallyDeletedTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

function calculatePercentCompleted(task) {
  return Math.floor(
    (task[AC.COMMITTED_IMAGES] / task[AC.TOTAL_CASE_IMAGES]) * 100
  );
}

function campaignDoesNotExistOrNoParams(campaign) {
  return !campaign || !campaign.hasOwnProperty(params);
}

async function getTask(url = '') {
  try {
    return await get(url);
  } catch (err) {
    logger
      .error('getTasks')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

async function getLabelersByNickNameData(url = '') {
  try {
    return await get(url);
  } catch (err) {
    logger
      .error('getLabelersByNickName')
      .data({ module: 'TasksList.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

export const getUserName = () => {
  const user = getUser();
  return user ? user.username : null;
};

const ComparatorByStrField = (a, b, field) =>
  a[field]?.toLowerCase().localeCompare(b[field]?.toLowerCase());

const tasksListLogic = (configs) => {
  return {
    async checkExecutionAndRetrieveOutput(executionArn, maxTimeout = 60000) {
      try {
        const running = 'RUNNING';
        const failed = 'FAILED';
        let stepFunctionOutput = running;
        let startTime = Date.now();

        while (stepFunctionOutput === running) {
          stepFunctionOutput = await this.checkExecutionStatus(executionArn);
          if (stepFunctionOutput === running) {
            if (Date.now() - startTime >= maxTimeout) {
              NotificationManager.error(
                'Timeout: No positive response within configured time'
              );
              throw new Error(
                'Timeout: No positive response within configured time'
              );
            }

            await new Promise((resolve) => setTimeout(resolve, 10000));
          } else if (stepFunctionOutput === failed) {
            return;
          }
        }
        return stepFunctionOutput;
      } catch (error) {
        console.error('Failed to retrieve Step Function output:', error);
      }
    },

    getNumberOfLabelersPerTask(config, campaignId) {
      const defaultLabelersNumber = getTier1LabelersNumber(config, campaignId);
      const campaign = getCampaign(config, campaignId);
      const numberOfLabelers = campaign && campaign[number_of_labelers];
      return numberOfLabelers ? numberOfLabelers : defaultLabelersNumber;
    },

    async getFavoriteCampaign(userName = '', campaignsToRolesMap) {
      let defaultCampaign = [...campaignsToRolesMap.keys()][0];
      try {
        const res = await get(
          `${configs.API_ENDPOINT}/getFavoriteCampaign?userId=${userName}`
        );
        return res?.FavoriteCampaign || defaultCampaign;
      } catch (err) {
        logger
          .error('getFavoriteCampaign')
          .data({ module: 'TasksList.logic', err: err })
          .end();
        return defaultCampaign;
      }
    },

    getUserCampaignsAndRoles(config, user, editCampaign = false) {
      if (editCampaign) {
        return getUserCampaignsIncludeHidden(config, user);
      }
      campaignsRoles = getUserCampaigns(config, user, campaignsRoles);
      return new Map(campaignsRoles);
    },

    getUserHiddenCampaignsAndRoles(config, user) {
      const hiddenCampaigns = getUserHiddenCampaigns(
        config,
        user,
        campaignsRoles
      );
      campaignsRoles = new Map(hiddenCampaigns);
      return new Map([...campaignsRoles.entries()].sort((a, b) => b[0] - a[0]));
    },

    getListOfUsers() {
      return get(`${configs.API_ENDPOINT}/getUsersList`).then((res) => {
        return res.sort((a, b) => ComparatorByStrField(a, b, 'UserId'));
      });
    },
    getListOfBatches(isEdit = false, batches = null) {
      const pathName = isEdit
        ? `${getBatchesList}?isEdit=${isEdit}&batchIds=${batches?.toString()}`
        : `${getBatchesList}`;
      return get(`${configs.API_ENDPOINT}/${pathName}`).then((res) => {
        return res.sort((a, b) => ComparatorByStrField(a, b, 'BatchName'));
      });
    },
    getListOfFindings() {
      return get(`${configs.API_ENDPOINT}/getFindingsList`).then((res) => {
        return res.sort((a, b) => ComparatorByStrField(a, b, 'FindingName'));
      });
    },
    postListOfFindings(newFinding) {
      return postData(`${configs.API_ENDPOINT}/addOrEditFinding`, newFinding);
    },
    postListofToothLevelFindings(newFinding) {
      return postData(
        `${configs.API_ENDPOINT}/addToothLevelFindings`,
        newFinding
      );
    },
    postAddOrEditUser(user) {
      return postData(`${configs.API_ENDPOINT}/addOrEditUser`, user);
    },
    postAddBatch(newBatch) {
      return postData(`${configs.API_ENDPOINT}/addBatch`, newBatch);
    },
    postAddCampaign(newCampaign) {
      return postData(`${configs.API_ENDPOINT}/addCampaign`, newCampaign);
    },
    deleteFinding(findingId) {
      return deleteData(
        `${configs.API_ENDPOINT}/deleteFinding?findingId=${findingId}`
      );
    },
    getTasksByTier(campaign, users, tier, id, role) {
      let counter = id,
        filterTasks;

      filterTasks = this.showRemovedTasksForSupervisorAndWatcher(
        campaign,
        role,
        tier
      );

      return filterTasks.map((task) => ({
        id: counter++,
        name: task,
        tier: tier,
      }));
    },
    showRemovedTasksForSupervisorAndWatcher(campaign, role, tier) {
      let mergedUserInactiveUserAndRoles, filteredTasks;
      if (role === Roles.SUPERVISOR || role === Roles.WATCHER) {
        const userRoles = campaign.users;
        const inactivatedRoles = campaign.inactivatedUserAndRoles || {};
        mergedUserInactiveUserAndRoles = { ...(userRoles || {}) };
        for (const [user, roles] of Object.entries(inactivatedRoles)) {
          if (mergedUserInactiveUserAndRoles[user]) {
            mergedUserInactiveUserAndRoles[user] = [
              ...new Set([...mergedUserInactiveUserAndRoles[user], ...roles]),
            ];
          } else {
            mergedUserInactiveUserAndRoles[user] = roles;
          }
        }
        filteredTasks = Object.keys(mergedUserInactiveUserAndRoles).filter(
          (name) => mergedUserInactiveUserAndRoles[name].includes(tier)
        );
      } else
        filteredTasks = Object.keys(campaign[users]).filter((name) =>
          campaign[users][name].includes(tier)
        );
      return filteredTasks;
    },
    getAssignees(config, campaignId, user, role) {
      if (!campaignsRoles.has(campaignId)) return [];
      if (!role) role = campaignsRoles.get(campaignId)[0];
      const campaign = getCampaign(config, campaignId);
      if (!campaign || !campaign[users] || !campaign[users][user]) return [];
      switch (role) {
        case Roles.SUPERVISOR:
        case Roles.TIER2_REVIEWER:
        case Roles.WATCHER:
          const tier1Tasks = this.getTasksByTier(
            campaign,
            users,
            tier1,
            0,
            role
          );
          const tier2Tasks = this.getTasksByTier(
            campaign,
            users,
            tier2,
            tier1Tasks.length,
            role
          );
          const tier3Tasks = this.getTasksByTier(
            campaign,
            users,
            tier3,
            tier1Tasks.length + tier2Tasks.length,
            role
          );

          return [...tier1Tasks, ...tier2Tasks, ...tier3Tasks];
        default:
          if (campaign[users][user].includes(tier1)) {
            return [{ name: user, tier: Tiers.TIER_1 }];
          } else if (campaign[users][user].includes(tier2)) {
            return [{ name: user, tier: Tiers.TIER_2 }];
          } else {
            return [{ name: user, tier: Tiers.TIER_3 }];
          }
      }
    },
    async getTasks(
      config,
      campaignId,
      labeler,
      isSupervisor = false,
      isTier2Reviewer = false,
      role,
      subNickName
    ) {
      const campaign = getCampaignList(config, campaignId);
      if (!campaign || !campaign.hasOwnProperty(params)) return [];
      const tasksSource = campaign[params][tasks_source];
      if (Object.keys(tasksSource)[0] === hard_coded) {
        return tasksSource[hard_coded][
          isSupervisor ? labeler.labeler : labeler.id
        ];
      } else {
        const batches = campaign[params][batch];
        const tierNumber = parseInt(isSupervisor ? labeler.tier[4] : role[4]); // example: role ="tier1", tierNumber = 1;
        let data = await getTasksData(
          `${configs.API_ENDPOINT}/getTasks/?assignee=${
            isSupervisor ? labeler.labeler : labeler.id
          }&batches=${batches}&tier=${tierNumber}&subNickname=${subNickName}`,
          isSupervisor,
          isTier2Reviewer
        );

        data = isSupervisor
          ? data
          : data.filter((task) => !task.InactivatedTask);
        if (!data) {
          return [];
        }
        data.forEach((t) => {
          t.id = `${t.TaskId}${t.Level ? t.Level[0] : ''} (${
            t.PercentCompleted
          }%)`;
          t.Type = t.Type || TaskTypes._5D;
          t.Level = t.Level || TaskLevels.IMAGE;
          t.Completed =
            t.Completed && t.Completed.toLowerCase() === 'true' ? true : false;
        });
        return data;
      }
    },
    async getTask(
      config,
      campaignId,
      labeler,
      labelersNumber,
      role,
      specificTask
    ) {
      const campaign = getCampaign(config, campaignId);
      if (!campaign || !campaign.hasOwnProperty(params)) return [];
      const tasksSource = campaign[params][tasks_source];
      const tierNumber = parseInt(role[4]); // example: role ="tier1", tierNumber = 1;
      if (Object.keys(campaign[params][tasks_source])[0] === hard_coded) {
        return tasksSource[hard_coded][labeler.id];
      } else {
        const batches = campaign[params][batch];
        const maxLabelers = isTier1(tierNumber) ? labelersNumber : 1;
        const task = await getTask(
          `${configs.API_ENDPOINT}/getNextTask/?assignee=${labeler.id}&batches=${batches}&bucket=${configs.BUCKET_NAME}&maxLabelers=${maxLabelers}&tier=${tierNumber}&specificTask=${specificTask}`
        );

        return task
          ? {
              TaskId: task[AC.TASK_SHORT_NICKNAME],
              PathToData: task[AC.OBJECT_KEY].split('/DataMarkingTask')[0],
              PercentCompleted: 0,
              Assignee: task[AC.ASSIGNEE],
              Tier: tierNumber,
              Batch: task[AC.BATCH],
              Type: task[AC.TYPE] || TaskTypes._5D,
              Level: task[AC.LEVEL],
              id: `${task[AC.TASK_SHORT_NICKNAME]}${
                task[AC.LEVEL] ? task[AC.LEVEL][0] : ''
              } (0%)`,
              InTier1: task[AC.IN_TIER1],
            }
          : null;
      }
    },
    async getDeletedTasks(config, campaignId) {
      const campaign = getCampaign(config, campaignId);
      if (campaignDoesNotExistOrNoParams(campaign)) return [];
      if (Object.keys(campaign[params][tasks_source])[0] === hard_coded) {
        return [];
      } else {
        const batches = campaign[params][batch];
        const promises = [];
        promises.push(
          getDeletedTasksData(
            `${configs.API_ENDPOINT}/getDeletedTasks?batches=${batches}`
          ),
          getPotentiallyDeletedTasksData(
            `${configs.API_ENDPOINT}/getPotentiallyDeletedTasks?batches=${batches}`
          )
        );
        const promisesList = await Promise.all(promises);
        return {
          deletedTasks: promisesList[0],
          potentiallyDeletedTasks: promisesList[1],
        };
      }
    },

    async getLabelersByNickName(config, campaignId, searchSubNickName) {
      const campaign = getCampaign(config, campaignId);
      if (campaignDoesNotExistOrNoParams(campaign)) return [];
      const batches = campaign[params][batch];
      return await getLabelersByNickNameData(
        `${configs.API_ENDPOINT}/getLabelersByNickName/?batches=${batches}&subNickName=${searchSubNickName}`
      );
    },

    async checkExecutionStatus(executionArn) {
      try {
        const res = await get(
          `${configs.API_ENDPOINT}/getResultStepFunction/?executionArn=${executionArn}`
        );
        return res.status || res;
      } catch (err) {
        return null;
      }
    },

    async importTasks() {
      return await post(`${configs.API_ENDPOINT}/importToTier2`);
    },

    async exportBatch(zipData) {
      const url = `${configs.API_ENDPOINT}/exportData`;
      const data = {
        selectedFile: zipData,
      };
      return await postData(url, data);
    },

    async getPresignedUrlPutImportData(selectedFileName) {
      return await get(
        `${configs.API_ENDPOINT}/getPresignedUrlImportData/?bucketName=${configs.BUCKET_NAME_CONFIG}&path=${selectedFileName}`
      );
    },
    async exportLabelsForAi(type, batches) {
      return await get(
        `${configs.API_ENDPOINT}/exportLabelsForAi/?&type=${type}&batches=${batches}`
      );
    },
    async unTakeTask(
      taskLevel,
      tier,
      selectedLabelerId,
      path,
      inactivatedTask
    ) {
      let url = `${
        configs.API_ENDPOINT
      }/returnTask/?taskLevel=${taskLevel.toLowerCase()}&tier=${tier}&selectedLabeler=${selectedLabelerId}&directory=${path}`;
      if (inactivatedTask) {
        url += `&inactivatedTask=${inactivatedTask}`;
      }
      console.log(url, typeof url);
      return await postData(url);
    },
    async getMonitoringReport(
      optionsForExport,
      batchToCampaignJson,
      dateFrom,
      dateTo,
      userName
    ) {
      return await postData(`${configs.API_ENDPOINT}/getMonitoringReport`, {
        optionsForExport: optionsForExport,
        batchToCampaignJson: batchToCampaignJson,
        dateFrom: dateFrom,
        dateTo: dateTo,
        userName: userName,
      });
    },

    async getOutputReport(path) {
      try {
        const res = await get(
          `${configs.API_ENDPOINT}/fetchConfig/?fileName=${path}`
        );

        return res;
      } catch (error) {
        logger
          .error('getOutputReport')
          .data({ module: 'TaskList.logic', err: error })
          .end();
        return [];
      }
    },
    async changeFavoriteCampaign(userName, favoriteCampaign) {
      return await postData(
        `${configs.API_ENDPOINT}/changeFavoriteCampaign?userName=${userName}&favoriteCampaign=${favoriteCampaign}`
      );
    },
    async sendBugReportByUser(user, bug, screenshotBlob, taskShortNickName) {
      return await postData(`${configs.API_ENDPOINT}/sendBugReportByUser`, {
        user: user,
        bug: bug,
        screenshotBlob: screenshotBlob,
        taskShortNickName: taskShortNickName,
      });
    },
    addOrEditActivityLogs(activityData) {
      return postData(
        `${configs.API_ENDPOINT}/addOrEditActivityLogs`,
        activityData
      );
    },
    async removeBatchFromCampaign(batchIds, campaignId) {
      const request = {
        batchIds: batchIds,
        campaignId: campaignId,
      };
      return await postData(
        `${configs.API_ENDPOINT}/removeBatchesFromCampaign`,
        request
      );
    },
    async sendActivityEmail(activityData) {
      return await postData(
        `${configs.API_ENDPOINT}/sendActivityEmail`,
        activityData
      );
    },
  };
};

export default tasksListLogic;
